
.p-05{ padding: .5rem }
.p-1{ padding: 1rem }
.p-15{ padding: 1.5rem }
.p-2{ padding: 2rem }
.p-25{ padding: 2.5rem }
.p-3{ padding: 3rem }
.p-35{ padding: 3.5rem }
.p-4{ padding: 4rem }
.p-45{ padding: 4.5rem }
.p-5{ padding: 5rem }
.p-55{ padding: 5.5rem }

.pt-05{ padding-top: .5rem }
.pt-1{ padding-top: 1rem }
.pt-15{ padding-top: 1.5rem }
.pt-2{ padding-top: 2rem }
.pt-25{ padding-top: 2.5rem }
.pt-3{ padding-top: 3rem }
.pt-35{ padding-top: 3.5rem }
.pt-4{ padding-top: 4rem }
.pt-45{ padding-top: 4.5rem }
.pt-5{ padding-top: 5rem }
.pt-55{ padding-top: 5.5rem }

.pr-05{ padding-right: .5rem }
.pr-1{ padding-right: 1rem }
.pr-15{ padding-right: 1.5rem }
.pr-2{ padding-right: 2rem }
.pr-25{ padding-right: 2.5rem }
.pr-3{ padding-right: 3rem }
.pr-35{ padding-right: 3.5rem }
.pr-4{ padding-right: 4rem }
.pr-45{ padding-right: 4.5rem }
.pr-5{ padding-right: 5rem }
.pr-55{ padding-right: 5.5rem }

.pb-05{ padding-bottom: .5rem }
.pb-1{ padding-bottom: 1rem }
.pb-15{ padding-bottom: 1.5rem }
.pb-2{ padding-bottom: 2rem }
.pb-25{ padding-bottom: 2.5rem }
.pb-3{ padding-bottom: 3rem }
.pb-35{ padding-bottom: 3.5rem }
.pb-4{ padding-bottom: 4rem }
.pb-45{ padding-bottom: 4.5rem }
.pb-5{ padding-bottom: 5rem }
.pb-55{ padding-bottom: 5.5rem }

.pl-05{ padding-left: .5rem }
.pl-1{ padding-left: 1rem }
.pl-15{ padding-left: 1.5rem }
.pl-2{ padding-left: 2rem }
.pl-25{ padding-left: 2.5rem }
.pl-3{ padding-left: 3rem }
.pl-35{ padding-left: 3.5rem }
.pl-4{ padding-left: 4rem }
.pl-45{ padding-left: 4.5rem }
.pl-5{ padding-left: 5rem }
.pl-55{ padding-left: 5.5rem }

.py-05{ padding-top: .5rem; padding-bottom: .5rem }
.py-1{ padding-top: 1rem; padding-bottom: 1rem }
.py-15{ padding-top: 1.5rem; padding-bottom: 1.5rem }
.py-2{ padding-top: 2rem; padding-bottom: 2rem }
.py-25{ padding-top: 2.5rem; padding-bottom: 2.5rem }
.py-3{ padding-top: 3rem; padding-bottom: 3rem }
.py-35{ padding-top: 3.5rem; padding-bottom: 3.5rem }
.py-4{ padding-top: 4rem; padding-bottom: 4rem }
.py-45{ padding-top: 4.5rem; padding-bottom: 4.5rem }
.py-5{ padding-top: 5rem; padding-bottom: 5rem }

.px-05{ padding-left: .5rem; padding-right: .5rem }
.px-1{ padding-left: 1rem; padding-right: 1rem }
.px-15{ padding-left: 1.5rem; padding-right: 1.5rem }
.px-2{ padding-left: 2rem; padding-right: 2rem }
.px-25{ padding-left: 2.5rem; padding-right: 2.5rem }
.px-3{ padding-left: 3rem; padding-right: 3rem }
.px-35{ padding-left: 3.5rem; padding-right: 3.5rem }
.px-4{ padding-left: 4rem; padding-right: 4rem }
.px-45{ padding-left: 4.5rem; padding-right: 4.5rem }
.px-5{ padding-left: 5rem; padding-right: 5rem }


.m-05{ margin: .5rem }
.m-1{ margin: 1rem }
.m-15{ margin: 1.5rem }
.m-2{ margin: 2rem }
.m-25{ margin: 2.5rem }
.m-3{ margin: 3rem }
.m-35{ margin: 3.5rem }
.m-4{ margin: 4rem }
.m-45{ margin: 4.5rem }
.m-5{ margin: 5rem }
.m-55{ margin: 5.5rem }

.mt-05{ margin-top: .5rem }
.mt-1{ margin-top: 1rem }
.mt-15{ margin-top: 1.5rem }
.mt-2{ margin-top: 2rem }
.mt-25{ margin-top: 2.5rem }
.mt-3{ margin-top: 3rem }
.mt-35{ margin-top: 3.5rem }
.mt-4{ margin-top: 4rem }
.mt-45{ margin-top: 4.5rem }
.mt-5{ margin-top: 5rem }
.mt-55{ margin-top: 5.5rem }

.mr-05{ margin-right: .5rem }
.mr-1{ margin-right: 1rem }
.mr-15{ margin-right: 1.5rem }
.mr-2{ margin-right: 2rem }
.mr-25{ margin-right: 2.5rem }
.mr-3{ margin-right: 3rem }
.mr-35{ margin-right: 3.5rem }
.mr-4{ margin-right: 4rem }
.mr-45{ margin-right: 4.5rem }
.mr-5{ margin-right: 5rem }
.mr-55{ margin-right: 5.5rem }

.mb-05{ margin-bottom: .5rem }
.mb-1{ margin-bottom: 1rem }
.mb-15{ margin-bottom: 1.5rem }
.mb-2{ margin-bottom: 2rem }
.mb-25{ margin-bottom: 2.5rem }
.mb-3{ margin-bottom: 3rem }
.mb-35{ margin-bottom: 3.5rem }
.mb-4{ margin-bottom: 4rem }
.mb-45{ margin-bottom: 4.5rem }
.mb-5{ margin-bottom: 5rem }

.ml-05{ margin-left: .5rem }
.ml-1{ margin-left: 1rem }
.ml-15{ margin-left: 1.5rem }
.ml-2{ margin-left: 2rem }
.ml-25{ margin-left: 2.5rem }
.ml-3{ margin-left: 3rem }
.ml-35{ margin-left: 3.5rem }
.ml-4{ margin-left: 4rem }
.ml-45{ margin-left: 4.5rem }
.ml-5{ margin-left: 5rem }
.ml-55{ margin-left: 5.5rem }

.my-05{ margin-top: .5rem; margin-bottom: .5rem }
.my-1{ margin-top: 1rem; margin-bottom: 1rem }
.my-15{ margin-top: 1.5rem; margin-bottom: 1.5rem }
.my-2{ margin-top: 2rem; margin-bottom: 2rem }
.my-25{ margin-top: 2.5rem; margin-bottom: 2.5rem }
.my-3{ margin-top: 3rem; margin-bottom: 3rem }
.my-35{ margin-top: 3.5rem; margin-bottom: 3.5rem }
.my-4{ margin-top: 4rem; margin-bottom: 4rem }
.my-45{ margin-top: 4.5rem; margin-bottom: 4.5rem }
.my-5{ margin-top: 5rem; margin-bottom: 5rem }

.mx-05{ margin-left: .5rem; margin-right: .5rem }
.mx-1{ margin-left: 1rem; margin-right: 1rem }
.mx-15{ margin-left: 1.5rem; margin-right: 1.5rem }
.mx-2{ margin-left: 2rem; margin-right: 2rem }
.mx-25{ margin-left: 2.5rem; margin-right: 2.5rem }
.mx-3{ margin-left: 3rem; margin-right: 3rem }
.mx-35{ margin-left: 3.5rem; margin-right: 3.5rem }
.mx-4{ margin-left: 4rem; margin-right: 4rem }
.mx-45{ margin-left: 4.5rem; margin-right: 4.5rem }
.mx-5{ margin-left: 5rem; margin-right: 5rem }




