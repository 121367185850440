.boxForm {
  width: 100%;
  height: auto;
  padding: 2rem 1rem;
  margin: 1rem auto;
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, .1);
  border-radius: .3rem;
}

form {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0 auto;
  // border-radius: 5px;
  // background-color: var(--color-white);
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

.form-control:focus {
  box-shadow: none;
  border: 1px solid var(--color-primary);
}


textarea {
  width: 100%;
  height: auto;
  min-height: 60px;
  max-height: 90px;
  resize: none;
}