.layout {
  width: 100%;
  height: auto;
  overflow: hidden;
  display: grid;
  grid-template-columns: 200px auto;
  transition: all 0.5s ease;
}

.ctnBody {
  width: 100%;
  height: auto;
  min-height: calc(100vh - 150px);
}

.sideBody {
  width: 100%;
  height: auto;
  display: grid;
  transition: grid-template-columns 0.3s ease-in-out;

  &.open {
    grid-template-columns: 200px 1fr;
  }

  &.close {
    grid-template-columns: 50px 1fr;
  }
}

.sidebarApp {
  transition: width 0.3s ease-in-out;
}