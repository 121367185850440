mat-form-field,
mat-form-field .mat-form-field-wrapper,
mat-form-field .mat-form-field-wrapper .mat-form-field-flex,
mat-form-field .mat-form-field-wrapper .mat-form-field-infix,
mat-form-field .mat-form-field-wrapper .mat-form-field-infix .mat-input-element {
  width: 100%;
}

mat-tab {
  width: 100%;
}

input {
  width: 100%;
}

.success-snackbar .mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--color-secondary) !important;
  font-size: 3rem !important;
}

.warning-snackbar .mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: var(--color-warning) !important;
  font-size: 3rem !important;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  color: #fefefe !important;
}


.headerForm {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem;
  background: var(--color-card-header);
  border-bottom: 1px solid #ccc;


}

.headerForm h3 {
  padding: 0;
  margin: 0;
  font-size: 1.5rem;
  text-wrap: balance;
}

.iconCerrar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary);
  background: #fefefe;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s ease;

}

.iconCerrar:hover {
  color: var(--color-primary);
  background-color: var(--color-card-header);
  transform: scale(1.01) rotate(180deg);
  box-shadow: 0 0 8px 3px rgba(0, 0, 0, .1);
}

