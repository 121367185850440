* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root{
  --color-primary: #8D212C;
  --color-primary-dark: #6c1b23;
  --color-secondary: #d23040;
  --color-tertiary: #ffce00;
  --color-quaternary: #6777ef;
  --color-quinary: #860380;
  --color-error: #960202;
  --color-green: #28a745;
  --color-gray: #7F858A;
  --color-gray-dark: #656b70;
  --color-card-header: #f7f7f7;
  // --color-bg: #defefe;
  --color-bg: #fefefe;

  --color-success: #28a745;
  --color-warning: #ffce00;
  --color-error: #002fff;
  --color-info: #007bff;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}