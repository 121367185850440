* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  text-align: center;
  margin: 0;
  padding: 0;
  // line-height: 1.6;
}

.pages h1 {
  font-size: 3rem;
  color: #555;
  color: var(--color-quinary);
  line-height: 5rem;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
    line-height: 3rem;
  }

  @media screen and (max-width: 580px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.pages h2 {
  font-size: 2rem;
  // color: #555;
  color: var(--color-quinary);
  line-height: 3rem;
  margin-top: 2rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 3rem;
  }

  @media screen and (max-width: 580px) {
    font-size: 1.5rem;
    // line-height: 2rem;
  }
}

.pages h3 {
  font-size: 1.5rem;
  color: #7c7b7b;
  line-height: 1.5rem;
  margin-top: 2rem;
  font-weight: 600;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: center;
  }

  @media screen and (max-width: 580px) {
    font-size: 1rem;
    // line-height: 2rem;
  }
}

// Global styles for the credits
.pages h6 {
  font-size: 1rem;
  color: #7c7b7b;
  font-weight: 600;
}

// Global styles for title the credits
.pages h5 {
  font-size: 1rem;
  color: var(--color-quinary);
  font-weight: 500;
  text-align: left;
}

p,
li {
  font-size: 1.2rem;
  line-height: 2rem;
  color: #333;
  text-wrap: pretty;
  margin-top: .5rem;
}

p {
  margin-top: 2rem;
}

ul,
ol {
  padding-left: 3rem;
  margin: 1rem auto;
}

.pages {
  width: 100%;
  max-width: 1024px;
  height: auto;
  padding: 1rem 2rem;
  margin: 0 auto;
}

.contentBody {
  width: 100%;
  height: auto;
  padding: 1rem;
  margin: 0 auto;
}

.line {
  width: 100%;
  height: .2rem;
  background-color: var(--color-quinary);
  border-radius: .1rem;
  margin: 0 auto 2rem auto;
}

.centrar {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centrarVertical {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.images {
  width: 100%;
  height: auto;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: .5rem;
  }

}

.alertNoData {
  width: 100%;
  height: auto;
  background: #FDEDED;
  color: #5F2120;
  padding: 1rem;
  border-radius: .3rem;
  text-align: center;
}