.swal2-popup .swal2-confirm {
  background-color: var(--color-secondary);
  color: #fff;
  transition: all .2s ease;

  &:focus {
    outline: none;
  }
}

.swal2-popup .swal2-confirm:hover {
  background-color: var(--color-primary);
  transform: scale(1.1) translateY(-3px);
  border: none;

  &:focus {
    outline: none;
  }
}